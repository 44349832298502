import React, { Component } from 'react'
import styled from 'styled-components'
import logo from './static/images/logo.png';
import arrowB from './static/images/common/icon_arrow_b.png'
import arrowA from './static/images/common/icon_arrow_a.png'
import arrowMenu from './static/images/common/icon_arrow_menu.png'
import arrowMenuA from './static/images/common/icon_arrow_menu_a.png'
// import bookB from './static/images/common/icon_book_b.png';
import menuB from './static/images/more_menu.png';
import menuA from './static/images/more_menu_a.png';
import bookA from './static/images/common/icon_book_a.png';
import left from './static/images/common/title_left.png';
import right from './static/images/common/title_right.png';
import overview from './static/images/overview.png'
import overviewFactoryA from './static/images/common/overview_factory_a.png'
import overviewFactoryB from './static/images/common/overview_factory_b.png'
import overviewLaunchA from './static/images/common/overview_launch_a.png'
import overviewLaunchB from './static/images/common/overview_launch_b.png'
import overviewLendingA from './static/images/common/overview_lending_a.png'
import overviewLendingB from './static/images/common/overview_lending_b.png'
import overviewAggregatorA from './static/images/common/overview_aggregator_a.png'
import overviewAggregatorB from './static/images/common/overview_aggregator_b.png'
import poolImg from './static/images/pool.png'
import priceFeed from './static/images/price_feed.png'
import bgLeft from './static/images/bg_left.png'
import bgLeftGreen from './static/images/bg_left_green.png'
import bgRight from './static/images/bg_right.png'
import bgPriceFeed from './static/images/bg_price_feed.png'
import bgFactory from './static/images/bg_factory.png'
import bgToken from './static/images/bg_token.png'
import riskCryptoA from './static/images/common/risk_crypto_a.png'
// import riskCryptoB from './static/images/common/risk_crypto_b.png'
import riskWhistleblowerA from './static/images/common/risk_whistleblower_a.png'
// import riskWhistleblowerB from './static/images/common/risk_whistleblower_b.png'
import riskReserveA from './static/images/common/risk_reserve_a.png'
// import riskReserveB from './static/images/common/risk_reserve_b.png'
import riskStakingA from './static/images/common/risk_staking_a.png'
// import riskStakingB from './static/images/common/risk_staking_b.png'
import token1A from './static/images/common/token_01_a.png'
// import token1B from './static/images/common/token_01_b.png'
import token2A from './static/images/common/token_02_a.png'
// import token2B from './static/images/common/token_02_b.png'
import token3A from './static/images/common/token_03_a.png'
// import token3B from './static/images/common/token_03_b.png'
import factoryImg from './static/images/factory.png'
import roadmap from './static/images/roadmap.png'
import roadmapS from './static/images/roadmap_s.png'
// import partners from './static/images/partners.png'
import backers from './static/images/backers.png'

import mediaCoverage01 from './static/images/common/media_coverage_01.png'
import mediaCoverage02 from './static/images/common/media_coverage_02.png'
import mediaCoverage03 from './static/images/common/media_coverage_03.png'
import mediaCoverage04 from './static/images/common/media_coverage_04.png'
import mediaCoverage05 from './static/images/common/media_coverage_05.png'
import mediaCoverage06 from './static/images/common/media_coverage_06.png'
import mediaCoverage07 from './static/images/common/media_coverage_07.png'
import mediaCoverage08 from './static/images/common/media_coverage_08.png'
import mediaCoverage09 from './static/images/common/media_coverage_09.png'
import mediaCoverageS01 from './static/images/common/media_coverage_s_01.png'
import mediaCoverageS02 from './static/images/common/media_coverage_s_02.png'
import mediaCoverageS03 from './static/images/common/media_coverage_s_03.png'
import mediaCoverageS04 from './static/images/common/media_coverage_s_04.png'
import mediaCoverageS05 from './static/images/common/media_coverage_s_05.png'
import mediaCoverageS06 from './static/images/common/media_coverage_s_06.png'
import mediaCoverageS07 from './static/images/common/media_coverage_s_07.png'
import mediaCoverageS08 from './static/images/common/media_coverage_s_08.png'
import mediaCoverageS09 from './static/images/common/media_coverage_s_09.png'

import telegram from './static/images/footer/icon_telegram.png'
import medium from './static/images/footer/icon_medium.png'
import twitter from './static/images/footer/icon_twitter.png'
import discord from './static/images/footer/icon_discord.png'
import github from './static/images/footer/icon_github.png'
import email from './static/images/footer/icon_email.png'


import './Home.css';

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isContent: true,
      isOverview: false,
      isToken: false,
      isRoadmap: false,
      isContact: false,
      isShowMenu: false
    }
    this.content = React.createRef()
    this.overview = React.createRef()
    this.token = React.createRef()
    this.roadmap = React.createRef()
    this.partners = React.createRef()
    this.contact = React.createRef()
  }

  changeMenu () {
    console.log(this.state.isShowMenu)
    this.setState((state)=>{
      return {
        isShowMenu: !state.isShowMenu
      }
    })
  }

  render() {
    const {isShowMenu} = this.state
    return (
      <HomeBox>
        <Header>
          <Tips>
            <a target="_blank" rel="noreferrer" href="https://t.me/Moma_Official">
            $MOMAT Contract Address: 0x865bB9A28041259b4baDAFD37799A288aAbbfC8c(ETH); 
            0x162EDC778dfD179A1E54E4bCAAf650Dc293BB2C9(HECO); 
            0x3fca6743e2fb55759fee767f3a68b2c06d699dc4(BSC);
            0x48b0632d25dcd9e1cec9eaca7cd97b82c452304f(FSN)</a>
            {/* <a target="_blank" rel="noreferrer" href="https://gleam.io/competitions/6QLO1-win-whitelist-for-moma-protocol-on-bounce">Moma Protocol’s IDO on Bounce: Whitelist is live from NOW</a> */}
          </Tips>
          <div className="box">
            <img src={logo} className="logo" alt="logo" />
            <MoreMenu>
              <div onClick={()=>{this.changeMenu()}}>
                <span className={isShowMenu ? "menua" : "menub"}></span>
              </div>
            </MoreMenu>
            
            {
              isShowMenu ? 
              <Menu>
                <span className={this.state.isContent ? 'menuSelect' : ''} onClick={(e)=>{this.scrollWhere(e,'content')}}>Home</span>
                <span className={this.state.isOverview ? 'menuSelect' : ''} onClick={(e)=>{this.scrollWhere(e,'overview')}}>Overview</span>
                <span className={this.state.isToken ? 'menuSelect' : ''} onClick={(e)=>{this.scrollWhere(e,'token')}}>Token</span>
                <span className={this.state.isRoadmap ? 'menuSelect' : ''} onClick={(e)=>{this.scrollWhere(e,'roadmap')}}>Roadmap</span>
                <span className="docs">
                  <span>Docs</span>
                  <div>
                    <a target="_blank" rel="noreferrer" href="https://docsend.com/view/7rdriske9pn5fhb9">DECK</a>
                    <a target="_blank" rel="noreferrer" href="https://docsend.com/view/dzyu756wkd2n4izq">Whitepaper</a>
                    <a target="_blank" rel="noreferrer" href="https://docsend.com/view/fmyqwazxkuutkcq6">Tokenomics</a>
                    <a target="_blank" rel="noreferrer" href="https://docs.moma.finance/">FAQ</a>
                    <a target="_blank" rel="noreferrer" href="/Onepager">One pager</a>
                    <a target="_blank" rel="noreferrer" href="https://docs.google.com/spreadsheets/d/1Hgcth-cH1zcuW6KnXzDA7sygZacUD_ErUoXxo64BXtc/edit?usp=sharing">Privilege Whitelist</a>
                  </div>
                </span>
                <span className={this.state.isContact ? 'menuSelect' : ''} onClick={(e)=>{this.scrollWhere(e,'contact')}}>Contact</span>
                <a target="_blank" rel="noreferrer" href="https://app.moma.finance/" className="app" >App</a>
              </Menu>
              : ''
            }
          </div>
        </Header>

        <Content ref={this.content}>
          <Main>
            <Title><span className="largeScreen">Factory, Launcher and Aggregator for Decentralized Money Markets </span><span className="smallScreen">Factory, <br/>Launcher and Aggregator<br /> for Decentralized Money Markets </span></Title>
            <Description>A Proprietary Solution to Meet the Growing Demands for Liquidity, Scalability and Speculation in DeFi Lending Markets</Description>
            <LinkBox>
              <LinkItem><a href="https://docsend.com/view/7rdriske9pn5fhb9" target="_blank" rel="noreferrer">Moma DECK</a></LinkItem>
              <LinkItem><a href="https://docsend.com/view/dzyu756wkd2n4izq" target="_blank" rel="noreferrer">Moma Whitepaper</a></LinkItem>
            </LinkBox>
          </Main>
        </Content>

        <Overview ref={this.overview}>
          <Box>
            <ChildTitle><span>Overview</span></ChildTitle>
            <ChildContent>The Moma Protocol uses a proprietary smart contract factory to produce, manage, accelerate and aggregate the lending markets, creating an ecosystem that can expand infinitely on lending liquidity and market diversity.</ChildContent>
            <ListBox>
              <div className="item factory">
                <h3>Factory</h3>
                <p>is a smart contract factory that can produce and manage Launch Pool and Lending Pool.</p>
              </div>
              <div className="item launch">
                <h3>Launch Pool</h3>
                <p>is a customizable Pre-Lending Pool designed for community mining. Community tokens can be distributed for any projects through the Launch Pool.</p>
              </div>
              <div className="item lending">
                <h3>Lending Pool</h3>
                <p>is a customizable lending market pool with an over-collateralized loan structure. This pool can support lending and borrowing in any markets.</p>
              </div>
              <div className="item aggregator">
                <h3>Aggregator</h3>
                <p>is a calculator and analyzer based on Pool and market data. It helps users solve their personalized DeFi demands of lending, borrowing and community mining of crypto assets.</p>
              </div>
            </ListBox>
          </Box>
        </Overview>

        <Factory>
          <Box>
            <ChildTitle><span>Factory</span><a name="pool"></a></ChildTitle>
            <ChildContent>Moma provides a smart contract factory for the production and management of Launch Pool and Lending Pool. As a Pool Builder, a user can customize the type of crypto assets and other parameters independently, and create their own Launch Pool and Lending Pool which they can fully operate by themselves to generate profits.</ChildContent>
            <ImgBox>
              <img className="factory" src={factoryImg} />
            </ImgBox>
          </Box>
        </Factory>

        <Pool>
          <Box>
            <ChildTitle><span>Launch pool & Lending pool</span><a name="pool"></a></ChildTitle>
            <ChildContent>Launch Pool activates, accelerates and creates the lending scenario for Lending Pool. Lending Pool provides the interest-bearing scenario to generate revenue for Launch Pool.</ChildContent>
            <ImgBox>
              <img className="pool" src={poolImg} />
            </ImgBox>
          </Box>
        </Pool>

        <PriceFeed>
          <Box>
            <ChildTitle><span>Price Feed</span></ChildTitle>
            <ChildContent>Moma provides a unique oracle system that combines the mechanisms of Committee Price Feed and Decentralized Price Feed.</ChildContent>
            <ImgBox>
              <img className="priceFeed" src={priceFeed} />
            </ImgBox>
          </Box>
        </PriceFeed>

        <RiskManagement>
          <Box>
            <ChildTitle><span>Risk Management</span></ChildTitle>
            <div className="listBox">
              <div className="item crypto">
                <h3>Crypto Asset Risk Rating Database</h3>
                <p>acts as a risk prompt list for identifying any potential risks related to the crypto asset in the Lending Pool. Moma users can take into account the rating score of a particular crypto asset from the database when they are deciding their participation strategies.</p>
              </div>
              <div className="item whistleblower">
                <h3>Whistleblower</h3>
                <p>is the core mechanism of Moma’s risk management framework. By staking Moma tokens, users can become Whistleblowers and submit risk warning information. Once the information is confirmed as valid, Whistleblower will receive incentives.</p>
              </div>
              <div className="item reserve">
                <h3>Reserve Pool</h3>
                <p>receives a portion of the interest revenue from Lending Pool and forms a fund pool. In situations where losses occur, the funds in the Reserve Pool will be mobilized for compensation.</p>
              </div>
              <div className="item staking">
                <h3>Staking Management Pool</h3>
                <p>is a staking pool formed by Pool Builders when they are upgrading their pools by staking Moma tokens. If any management problems occur during the pool operation process and result in losses on the user side, Moma tokens in the Staking Management Pool will be mobilized to compensate the affected users.
</p>
              </div>
            </div>
          </Box>
        </RiskManagement>

        <TokenEconomy ref={this.token}>
          <Box>
            <ChildTitle><span>Token Economy</span><a name="token"></a></ChildTitle>
            <ChildContent>Moma Protocol Token (ticker: MOMAT) is the native governance token of the Moma protocol. Total supply of MOMAT is 100 million. The utility and use cases of Moma include but are not limited to:</ChildContent>
            <div className="listBox">
              <div className="item token1">
                <h3>Incentives</h3>
                <p>$MOMAT holders can obtain partial incentives of the platform transaction fee.</p>
              </div>
              <div className="item token2">
                <h3>Privileges</h3>
                <p>$MOMAT holders can enjoy certain privileges and interests for future use of the platform’s lending market or other services.</p>
              </div>
              <div className="item token3">
                <h3>Governance</h3>
                <p>$MOMAT holders can vote to participate in the development and key governance of the platform.</p>
              </div>
            </div>
          </Box>
        </TokenEconomy>

        <Roadmap ref={this.roadmap}>
          <Box>
            <ChildTitle><span>Roadmap</span><a name="roadmap"></a></ChildTitle>
          </Box>
          <div className="imgBox"><img className="b" src={roadmap}/> <img className="s" src={roadmapS} /></div>
        </Roadmap>

        <Partners ref={this.partners}>
          <Box>
            <ChildTitle><span>Backers</span></ChildTitle>
          </Box>
          <div className="imgBox"><img src={backers}/></div>
        </Partners>

        <MediaCoverage>
          <Box>
            <ChildTitle><span>Media Coverage</span></ChildTitle>
          </Box>
          <div className="content">
            <a className="item" target="_blank" rel="noreferrer" href="https://coinmarketcap.com/headlines/news/moma-protocol-raises-2-25m-to-create-infinite-liquidity-market-for-defi-lending/">
              <div className="top">
                <img className="big" src={mediaCoverage01}/>
                <p>Moma Protocol Raises $2.25M To Create Infinite Liquidity Market For DeFi Lending</p>
              </div>
              <img className="small" src={mediaCoverageS01}/>
            </a>
            <a className="item" target="_blank" rel="noreferrer" href="https://www.investing.com/news/cryptocurrency-news/moma-protocol-successfully-completes-225m-funding-round-2497890">
              <div className="top">
                <img className="big" src={mediaCoverage02}/>
                <p>Moma Protocol Successfully Completes $2.25M Funding Round</p>
              </div>
              <img className="small" src={mediaCoverageS02}/>
            </a>
            {/* <a className="item" target="_blank" rel="noreferrer" href="https://dailyhodl.com/2021/05/20/moma-protocol-to-launch-ido-on-paid-ignition-after-recording-over-200000-whitelist-registrations-on-genpads-pre-ido/">
              <div className="top">
                <img className="big" src={mediaCoverage03}/>
                <p>Moma Protocol To Launch IDO on Paid Ignition After Recording Over 200,000 Whitelist Registrat-ions on GenPad’s Pre-IDO</p>
              </div>
              <img className="small" src={mediaCoverageS03}/>
            </a> */}



            <a className="item" target="_blank" rel="noreferrer" href="https://www.marketwatch.com/press-release/moma-protocol-completes-a-225-million-round-to-create-infinite-liquidity-for-long-tail-defi-lending-markets-2021-05-10">
              <div className="top">
                <img className="big" src={mediaCoverage04}/>
                <p>Moma Protocol Completes a $2.25 Million Round to Create Infinite Liquidity for Long-tail DeFi Lending Markets</p>
              </div>
              <img className="small" src={mediaCoverageS04}/>
            </a>
            <a className="item" target="_blank" rel="noreferrer" href="https://www.newsbtc.com/news/company/moma-protocol-raises-2-25-million-to-focus-on-long-tail-assets-in-defi/">
              <div className="top">
                <img className="big" src={mediaCoverage05}/>
                <p>Moma Protocol Raises $2.25 Million To Focus on Long-Tail Assets In DeFi</p>
              </div>
              <img className="small" src={mediaCoverageS05}/>
            </a>
            <a className="item" target="_blank" rel="noreferrer" href="https://coincodex.com/article/11196/decentralized-money-market-project-moma-protocol-raises-225-million-round/">
              <div className="top">
                <img className="big" src={mediaCoverage06}/>
                <p>Decentralized Money Market Project Moma Protocol Raises $2.25 Million Round</p>
              </div>
              <img className="small" src={mediaCoverageS06}/>
            </a>
            <a className="item" target="_blank" rel="noreferrer" href="https://www.benzinga.com/markets/cryptocurrency/21/05/20991808/2-25-million-raised-for-moma-protocols-infinite-liquidity-and-permissionless-lending">
              <div className="top">
                <img className="big" src={mediaCoverage07}/>
                <p>$2.25 Million Raised For Moma Protocol's Infinite Liquidity And Permissionless Lending</p>
              </div>
              <img className="small" src={mediaCoverageS07}/>
            </a>
            <a className="item" target="_blank" rel="noreferrer" href="https://www.ibtimes.sg/moma-protocol-raises-over-2m-create-liquid-defi-lending-market-57308">
              <div className="top">
                <img className="big" src={mediaCoverage08}/>
                <p>Moma Protocol Raises Over $2M to Create Liquid Defi Lending Market</p>
              </div>
              <img className="small" src={mediaCoverageS08}/>
            </a>
            <a className="item" target="_blank" rel="noreferrer" href="https://apnews.com/press-release/pr-newswire/technology-190841b4db56112bb5e3a8a644b7f688">
              <div className="top">
                <img className="big" src={mediaCoverage09}/>
                <p>Moma Protocol Completes a $2.25 Million Round to Create Infinite Liquidity for Long-tail DeFi Lending Markets</p>
              </div>
              <img className="small" src={mediaCoverageS09}/>
            </a>
          </div>
        </MediaCoverage>

        <Footer ref={this.contact}>
          <div className="top">
            <img src={logo}  />
          </div>
          <div className="box">
            <div className="left">
              <p>Copyright: 2021 MOMA Protocol. All Rights Reserved</p>
              <div className="list">
                <div className="item">
                  <a target="_blank" rel="noreferrer" href="https://t.me/Moma_Official"><img src={telegram} /></a>
                </div>
                <div className="item">
                  <a target="_blank" rel="noreferrer" href="https://momafinance.medium.com/"><img src={medium} /></a>
                </div>
                <div className="item">
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/Moma_Finance"><img src={twitter} /></a>
                </div>
                <div className="item">
                  <a target="_blank" rel="noreferrer" href="https://discord.gg/VrrTqAm27j"><img src={discord} /></a>
                </div>
                <div className="item">
                  <a target="_blank" rel="noreferrer" href="https://github.com/moma-finance/moma-protocol"><img src={github} /></a>
                </div>
                <div className="item">
                  <a href="mailto:contact@moma.finance"><img src={email} /></a>
                </div>
              </div>
            </div>
            <div className="right">
              <dl>
                <dt><span>DISCLAIMER</span></dt>
                <dd>All information on this website or via other official channels is given solely for educational purposes and is only aimed at institutional and sophisticated individual investors. Nothing on this Platform should be construed as a suggestion for investment by MOMA or anyone else.</dd>
              </dl>
            </div>
          </div>
        </Footer>

        <div className="animate" id="animateBox"></div>
      </HomeBox>
    )
  }
  scrollWhere (e, topValue){
    // console.log(e)
    if (document.getElementsByClassName('menuSelect').length) {
      document.getElementsByClassName('menuSelect')[0].setAttribute('class','');
    }
    
    e.currentTarget.className = "menuSelect"
    let offsetTop = this[topValue].current.offsetTop - 100
    const scrollOptions = {
      top: offsetTop,
      behavior: 'smooth'
    }
    window.scrollTo(scrollOptions)
  }
  componentDidMount(){
    loops()

    let width =  document.documentElement.clientWidth;

    if (width > 750) {
      this.setState({
          isShowMenu: true
      })
    }
    window.onload = ()=>{

      window.onscroll = () => {
        let a = document.documentElement.scrollTop || document.body.scrollTop
        let content = 0
        let overview = this.overview.current.offsetTop - 100
        let token = this.token.current.offsetTop - 100
        let roadmap = this.roadmap.current.offsetTop - 100
        let contact = this.partners.current.offsetTop
        if ((content < a || a === content) && a < (overview - 50)) {
          this.setState({
            isContent: true
          })
        } else {
          this.setState({
            isContent: false
          })
        }
        if ((a > overview || a === overview) && a < token - 100) {
          this.setState({
            isOverview: true
          })
        } else {
          this.setState({
            isOverview: false
          })
        }
        if ((a === token || a > token) && a < token + 700) {
          this.setState({
            isToken: true
          })
        } else {
          this.setState({
            isToken: false
          })
        }
        if ((a === roadmap || a > roadmap) && a < roadmap + 750) {
          this.setState({
            isRoadmap: true
          })
        } else {
          this.setState({
            isRoadmap: false
          })
        }
        if (a === contact || a > contact - 100) {
          this.setState({
            isContact: true
          })
        } else {
          this.setState({
            isContact: false
          })
        }
      }
    }
  }

}

const loops = () => {
  /**
   *3D海洋效应与 Canvas2D
  * 您可以更改注释 "效果属性" 下的属性
  */

  // Init Context
  let c = document.createElement('canvas').getContext('2d')
  let postctx = document.getElementById('animateBox').appendChild(document.createElement('canvas')).getContext('2d')
  let canvas = c.canvas
  let vertices = []

  // Effect Properties
  let vertexCount = 7000
  let vertexSize = 3
  let oceanWidth = 204
  let oceanHeight = -80
  let gridSize = 32;
  let waveSize = 16;
  let perspective = 100;

  // Common variables
  let depth = (vertexCount / oceanWidth * gridSize)
  let frame = 0
  let { sin, cos, tan, PI } = Math

  // Render loop
  let loop = () => {
    let rad = sin(frame / 100) * PI / 20
    let rad2 = sin(frame / 50) * PI / 10
    frame++
    if (postctx.canvas.width !== postctx.canvas.offsetWidth || postctx.canvas.height !== postctx.canvas.offsetHeight) { 
      postctx.canvas.width = canvas.width = postctx.canvas.offsetWidth
      postctx.canvas.height = canvas.height = postctx.canvas.offsetHeight
    }

    
    // c.fillStyle = `hsl(200deg, 100%, 2%)`
    c.fillStyle = "#171717"
    c.fillRect(0, 0, canvas.width, canvas.height)
    c.save()
    c.translate(canvas.width / 2, canvas.height / 2)
    
    c.beginPath()
    vertices.forEach((vertex, i) => {
      let ni = i + oceanWidth
      let x = vertex[0] - frame % (gridSize * 2)
      let z = vertex[2] - frame * 2 % gridSize + (i % 2 === 0 ? gridSize / 2 : 0)
      let wave = (cos(frame / 45 + x / 50) - sin(frame / 20 + z / 50) + sin(frame / 30 + z*x / 10000))
      let y = vertex[1] + wave * waveSize
      let a = Math.max(0, 1 - (Math.sqrt(x ** 2 + z ** 2)) / depth)
      let tx, ty, tz
      
      y -= oceanHeight
      
      // Transformation variables
      tx = x
      ty = y
      tz = z

      // Rotation Y
      tx = x * cos(rad) + z * sin(rad)
      tz = -x * sin(rad) + z * cos(rad)
      
      x = tx
      y = ty
      z = tz
      
      // Rotation Z
      tx = x * cos(rad) - y * sin(rad)
      ty = x * sin(rad) + y * cos(rad) 
      
      x = tx;
      y = ty;
      z = tz;
      
      // Rotation X
      
      ty = y * cos(rad2) - z * sin(rad2)
      tz = y * sin(rad2) + z * cos(rad2)
      
      x = tx;
      y = ty;
      z = tz;

      x /= z / perspective
      y /= z / perspective
      
      
          
      if (a < 0.01) return
      if (z < 0) return
    
      
      c.globalAlpha = a
      // c.fillStyle = `hsl(${180 + wave * 20}deg, 100%, 50%)`
      c.fillStyle = `hsl(${7 + wave * 20}deg, 95%, 52%)`
      c.fillRect(x - a * vertexSize / 2, y - a * vertexSize / 2, a * vertexSize, a * vertexSize)
      c.globalAlpha = 1
    })
    c.restore()
    
    // Post-processing
    postctx.drawImage(canvas, 0, 0)
    
    // postctx.globalCompositeOperation = "screen"
    // postctx.filter = 'blur(16px)'
    // postctx.drawImage(canvas, 0, 0)
    // postctx.filter = 'blur(0)'
    // postctx.globalCompositeOperation = "source-over"
    
    requestAnimationFrame(loop)
  }

  // Generating dots
  for (let i = 0; i < vertexCount; i++) {
    let x = i % oceanWidth
    let y = 0
    let z = i / oceanWidth >> 0
    let offset = oceanWidth / 2
    vertices.push([(-offset + x) * gridSize, y * gridSize, z * gridSize])
  }

  loop()

}


const HomeBox = styled.div`
  position: relative;
  .animate {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh);
    overflow: hidden;
    z-index: -1;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
`
const Tips = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #F9770E;
  a {
    font-size: 16px;
    width: 1200px;
    text-align: center;
    color: #fff;
    font-family: PingFangSC-Regular;
    font-weight: bold;
  }
  @media screen and (max-width: 750px) {
    width: 7.5rem;
    height: auto;
    padding: 0.1rem 0;
    a {
      font-size: 0.2rem;
      width: auto;
      padding: 0 0.2rem;
    }
  }
`

const Header = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: #171717;
  .box {
    display: flex;
    justify-content: space-between;
    width: 1176px;
    padding: 20px 0;
    margin: 0 auto;
    .logo {
      height: 32px;
    }
  }
  @media screen and (max-width: 750px) {
    width: 7.5rem;
    .box {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      padding: 0.3rem 0.35rem;
      margin: 0;
      .logo {
        height: 0.38rem;
      }
    }
  }
`

const MoreMenu = styled.div`
  display: none;
  @media screen and (max-width: 750px) {
    display: block;
    div {
      display: flex;
      flex-direction: column;
      width: 0.56rem;
      height: 0.27rem;
      padding: 0.05rem 0.1rem;
      span {
        width: 0.56rem;
        height: 0.27rem;
        background: url() center center;
        background-size: 0.56rem 0.27rem;
      }
      .menub {
        background-image: url(${menuB});
      }
      .menua {
        background-image: url(${menuA});
      }
    }
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  a.app {
    font-size: 18px;
    color: #F9770E;
    border: 1px solid #F9770E;
    border-radius: 4px;
    padding: 5px 15px;
    margin-left: 45px;
    font-style: normal;
    text-decoration: none;
    &:hover {
      color: #fff;
      border-color: #F9770E;
      background-color: #F9770E;
    }
  }
  & > span {
    font-size: 14px;
    line-height: 3;
    height: 100%;
    color: #fff;
    cursor: pointer;
  }
  & > span:hover ,& > span.menuSelect {
    color: #F9770E;
  }
  & > span.docs {
    position: relative;
    span{
      position: relative;
      padding: 5px 24px;
      border: 1px solid rgba(255,255,255,0.2);
      border-radius: 12px;
      &::after {
        content: '';
        position: absolute;
        right: 10px;
        top: 10px;
        width: 7px;
        height: 5px;
        background: url(${arrowB}) no-repeat center center;
        background-size: 7px 5px;
      }
    }
    div {
      display: none;
      position: absolute;
      left: 0;
      top: 40px;
      box-sizing: border-box;
      /* display: flex; */
      flex-direction: column;
      align-items: center;
      width: 120px;
      padding: 9px 0;
      border-radius: 10px;
      background-color: rgba(48, 48, 48, 0.5);
      a {
        font-size: 12px;
        line-height: 14px;
        text-decoration: none;
        color: rgba(255,255,255,0.2);
        &:hover {
          color: #fff;
        }
      }
      a + a {
        margin-top: 8px;
      }
    }
    &:hover {
      span {
        border-color: #F9770E;
        &::after {
          background-image: url(${arrowA});
        }
      }
      div {
        display: flex;
      }
    }
  }
  & > span + span {
    margin-left: 45px;
  }

  @media screen and (max-width: 750px) {
    position: absolute;
    right:0;
    top: 0.97rem;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    width: 2.6rem;
    padding: 0.3rem 0.38rem;
    background-color: #303030;
    border-radius: 0.1rem;
    a.app {
      margin-left: 0;
      margin-top: 0.3rem;
      font-size: 0.25rem;
    }
    & > span {
      width: 100%;
      padding: 0.2rem 0;
      font-size: 0.24rem;
      line-height: 1;
      height: 100%;
      color: #fff;
    }
    & > span.docs {
      position: relative;
      span{
        position: relative;
        padding: 0;
        display: block;
        border: 0;
        border-radius: 0;
        &::after {
          content: '';
          position: absolute;
          right: -0.1rem;
          top: 0.03rem;
          width: 0.08rem;
          height: 0.12rem;
          background: url(${arrowMenu}) no-repeat center center;
          background-size: 0.08rem 0.12rem;
        }
      }
      div {
        display: none;
        position: relative;
        left: 0;
        top: 0;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 0.2rem;
        margin-bottom: -0.2rem;
        padding: 0.2rem 0.28rem;
        border-radius: 0.06rem;
        background-color: rgba(23, 23, 23, 0.5);
        a {
          width: 100%;
          padding: 0.12rem 0;
          font-size: 0.24rem;
          line-height: 1;
          text-decoration: none;
          color: #fff;
        }
        a + a {
          margin-top: 0;
        }
      }
      &:hover {
        span {
          border-color: transparent;
          &::after {
            width: 0.12rem;
            height: 0.08rem;
            background-image: url(${arrowMenuA});
            background-size: 0.12rem 0.08rem;
          }
        }
        div {
          display: flex;
        }
      }
    }
    & > span + span {
      margin-left: 0;
    }
  }
`

const Content = styled.div`
  box-sizing: border-box;
  background-size: cover;
  padding: 150px 0;
  @media screen and (max-width: 750px) {
    padding-top: 2rem 0;
  }
`

const Main = styled.div`
  width: 1176px;
  margin: 0 auto;
  padding-left: 20px;
  padding-bottom: 100px;
  @media screen and (max-width: 750px) {
    box-sizing: border-box;
    width: 7.5rem;
    padding-right:  0 0.35rem
  }
`

const Title = styled.h1`
  width: 776px;
  margin: 0 0 50px;
  color: #fff;
  .largeScreen {
    font-size: 48px;
    line-height: 72px;
  }
  .smallScreen {
    display: none;
  }
  @media screen and (max-width: 750px) {
    width: 5rem;
    margin-bottom: 0.54rem;
    font-size: 0;
    .largeScreen {
      display: none;
    }
    .smallScreen {
      display: inline;
      font-size: 0.36rem;
      line-height: 0.8rem;
    }
  }
  
`

const Description = styled.p`
  width: 595px;
  margin: 0 0 80px;
  font-size: 24px;
  line-height: 48px;
  color: rgba(255,255,255,0.5);
  @media screen and (max-width: 750px) {
    width: 4.3rem;
    font-size: 0.22rem;
    line-height: 0.5rem;
    margin-bottom: 0.6rem;
  }
`

const LinkBox = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const LinkItem = styled.div`
  a {
    display: inline-block;
    padding-left: 42px;
    font-size: 24px;
    line-height: 40px;
    color: #F9770E;
    background: url(${bookA}) no-repeat left center;
    background-size: 26px 32px;
    /* text-decoration: none; */
    /* &:hover {
      color: #F9770E;
      text-decoration: underline;
      background-image: url(${bookA});
    } */
  }
  & + & {
    margin-left: 24px;
  }
  @media screen and (max-width: 750px) {
    font-size: 0;
    a {
      padding-left: 0.42rem;
      font-size: 0.24rem;
      line-height: 0.4rem;
      background-size: 0.32rem 0.41rem;
    }
    & + & {
      margin-top: 0.5rem;
      margin-left: 0;
    }
  }
`

const Overview = styled.div`
  padding-top: 60px;
  padding-bottom: 130px;
  background: url(${overview}) no-repeat center center;
  background-size: cover;
  @media screen and (max-width: 750px) {
    padding-top: 2rem;
    padding-bottom: 1.3rem;
  }
`

const Box = styled.div`
  width: 1176px;
  margin: 0 auto;
  @media screen and (max-width: 750px) {
    width: 6.78rem;
  }
`

const ChildTitle = styled.h2`
  margin: 0 0 30px;
  text-align: center;
  span {
    padding-left: 120px;
    padding-right: 120px;
    font-size: 48px;
    line-height: 56px;
    color: #F9770E;
    background: url(${left}) no-repeat left center,url(${right}) no-repeat right center;
  }
  @media screen and (max-width: 750px) {
    margin-bottom: 0.2rem;
    font-size: 0;
    span {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      font-size: 0.36rem;
      line-height: 0.42rem;
      background-size: 0.6rem;
    }
  }
`

const ChildContent = styled.p`
  width: 998px;
  margin: 0 auto;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  @media screen and (max-width: 750px) {
    width: 100%;
    font-size: 0.28rem;
    line-height: 0.5rem;
    text-align: justify;
  }
`

const ListBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 54px;
  .item {
    width: 243px;
    padding-top: 148px;
    text-align: center;
    background: url() no-repeat top center;
    background-size: 148px 148px;
    h3 {
      margin: 0 0 8px;
      font-size: 24px;
      color: #F9770E;
      /* color: rgba(249, 119, 14, 0.4); */
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      /* color: rgba(255,255,255,0.4); */
    }
    &:hover {
      h3 {
        color: #F9770E;
      }
      p {
        color: #fff;
      }
    }
  }
  .factory {
    background-image: url(${overviewFactoryA});
    /* background-image: url(${overviewFactoryB}); */
    /* &:hover {
      background-image: url(${overviewFactoryA});
    } */
  }
  .launch {
    margin-top: 128px;
    background-image: url(${overviewLaunchA});
    /* background-image: url(${overviewLaunchB}); */
    /* &:hover {
      background-image: url(${overviewLaunchA});
    } */
  }
  .lending {
    background-image: url(${overviewLendingA});
    /* background-image: url(${overviewLendingB}); */
    /* &:hover {
      background-image: url(${overviewLendingA});
    } */
  }
  .aggregator {
    margin-top: 128px;
    background-image: url(${overviewAggregatorA});
    /* background-image: url(${overviewAggregatorB}); */
    /* &:hover {
      background-image: url(${overviewAggregatorA});
    } */
  }
  @media screen and (max-width: 750px) {
    display: flex;
    flex-direction: column;
    margin-top: 0.9rem;
    .item {
      width: 3.28rem;
      padding-top: 1rem;
      text-align: left;
      background: url() no-repeat top left;
      background-size: 1rem 1rem;
      h3 {
        margin: 0 0 8px;
        font-size: 0.28rem;
        color: #F9770E;
      }
      p {
        margin: 0;
        font-size: 0.22rem;
        line-height: 1.5;
        color: #fff;
      }
    }
    .factory {
      background-image: url(${overviewFactoryA});
    }
    div.launch {
      margin-top: -1rem;
      margin-left: 3.5rem;
      background-image: url(${overviewLaunchA});
    }
    .lending {
      margin-top: -1rem;
      background-image: url(${overviewLendingA});
    }
    .aggregator {
      margin-top: -1rem;
      margin-left: 3.5rem;
      background-image: url(${overviewAggregatorA});
    }
  }
`

const Factory = styled.div`
  padding: 50px 0;
  background: url(${bgFactory}) no-repeat center center;
  background-size: cover;
`

const Pool = styled.div`
  padding: 50px 0;
  background: url(${bgLeft}) no-repeat left 364px ,url(${bgRight}) no-repeat right 40px;
  background-size: 123px 339px, 126px 339px;
  @media screen and (max-width: 750px) {
    padding: 0.6rem 0;
    background: url(${bgLeft}) no-repeat left 4.5rem ,url(${bgRight}) no-repeat right 0.6rem;
    background-size: 0.8rem , 0.8rem ;
  }
`

const ImgBox = styled.div`
  text-align: center;
  img.factory {
    width: 1138px;
    margin-top: 35px;
  }
  img.pool {
    width: 1059px;
    margin-top: 76px;
  }
  img.priceFeed {
    width: 1036px;
    margin-top: 30px;
  }
  @media screen and (max-width: 750px) {
    img.factory,img.pool,img.priceFeed {
      width: 100%;
      margin-top: 0.4rem;
    }
  }
`

const PriceFeed = styled.div`
  padding: 66px 0 33px;
  background: url(${bgPriceFeed}) no-repeat center center;
  background-size: cover;
  @media screen and (max-width: 750px) {
    padding: 0.8rem 0 0.4rem;
  }
`

const RiskManagement = styled.div`
  padding: 40px 0;
  background: url(${bgLeftGreen}) no-repeat left 438px ,url(${bgRight}) no-repeat right 170px;
  background-size: 221px 617px, 152px 407px;
  .listBox {
    margin-top: 56px;
    .item {
      width: 674px;
      padding-top: 110px;
      margin: 0 auto;
      text-align: center;
      background: url() no-repeat top center;
      background-size: 102px 94px;
      text-align: center;
      h3 {
        margin: 0 0 8px;
        font-size: 24px;
        line-height: 32px;
        color: #F9770E;
        /* color: rgba(249, 119, 14, 0.4); */
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 32px;
        color: #fff;
        /* color: rgba(255,255,255,0.4); */
      }
      /* &:hover {
        h3 {
          color: #F9770E;
        }
        p {
          color: #fff;
        }
      } */
    }
    .crypto {
      background-image: url(${riskCryptoA});
    }
    .whistleblower{
      background-image: url(${riskWhistleblowerA})
    }
    .reserve{
      background-image: url(${riskReserveA})
    }
    .staking{
      background-image: url(${riskStakingA})
    }
    .item + .item {
      margin-top: 56px;
    }
  }
  @media screen and (max-width: 750px) {
    padding: 0.5rem 0;
    background: url(${bgLeftGreen}) no-repeat left 9rem ,url(${bgRight}) no-repeat right 1.5rem;
    background-size: 1rem, 1.2rem;
    .listBox {
      margin-top: 0.6rem;
      .item {
        width: 100%;
        padding-top: 1rem;
        margin: 0 auto;
        text-align: center;
        background: url() no-repeat top center;
        background-size: 1.02rem 0.94rem;
        text-align: center;
        h3 {
          margin: 0 0 8px;
          font-size: 0.28rem;
          line-height: 0.32rem;
          color: #F9770E;
        }
        p {
          margin: 0;
          font-size: 0.22rem;
          line-height: 0.32rem;
          color: #fff;
          text-align: left;
        }
      }
      .crypto {
        background-image: url(${riskCryptoA});
      }
      .whistleblower{
        background-image: url(${riskWhistleblowerA})
      }
      .reserve{
        background-image: url(${riskReserveA})
      }
      .staking{
        background-image: url(${riskStakingA})
      }
      .item + .item {
        margin-top: 0.5rem;
      }
    }
  }
`

const TokenEconomy = styled.div`
  padding: 40px 0 60px;
  background: url(${bgToken}) no-repeat center center;
  background-size: cover;
  .listBox {
    display: flex;
    justify-content: space-between;
    margin-top: 54px;
    .item {
      width: 250px;
      padding-top: 180px;
      margin: 0 auto;
      background: url() no-repeat top center;
      background-size: 180px 180px;
      text-align: center;
      h3 {
        margin: 20px 0 0;
        font-size: 24px;
        line-height: 32px;
        color: #F9770E;
        /* color: rgba(249, 119, 14, 0.4); */
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 32px;
        color: #fff;
        /* color: rgba(255,255,255,0.4); */
      }
      /* &:hover {
        h3 {
          color: #F9770E;
        }
        p {
          color: #fff;
        }
      } */
    }
    .token1 {
      background-image: url(${token1A});
    }
    .token2{
      background-image: url(${token2A})
    }
    .token3{
      background-image: url(${token3A})
    }
  }
  @media screen and (max-width: 750px) {
    padding: 1rem 0 1rem;
    .listBox {
      display: block;
      margin-top: 0.54rem;
      .item {
        width: 3.28rem;
        padding-top: 1rem;
        margin: 0;
        text-align: left;
        background: url() no-repeat top left;
        background-size: 1rem;
        h3 {
          margin: 0.2rem 0 0;
          font-size: 0.28rem;
          line-height: 0.32rem;
          color: #F9770E;
        }
        p {
          margin: 0.2rem 0 0;
          font-size: 0.22rem;
          line-height: 0.32rem;
          color: #fff;
        }
      }
      .token1 {
        background-image: url(${token1A});
      }
      .token2{
        margin: -1rem 0 0 3.5rem;
        background-image: url(${token2A})
      }
      .token3{
        margin-top: -1rem;
        background-image: url(${token3A})
      }
    }
  }
`

const Roadmap = styled.div`
  padding: 40px 0 45px;
  .imgBox {
    width: 1440px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    .s {
      display: none;
    }
  }
  @media screen and (max-width: 750px) {
    padding: 1rem 0.35rem 1rem;
    .imgBox {
      width: 100%;
      .b {
        display: none;
      }
      .s {
        display: inline;
      }
    }
  }
`

const Partners = styled.div`
  padding: 70px 0 67px;
  background: url(${bgLeft}) no-repeat left 412px ,url(${bgRight}) no-repeat right 88px;
  background-size: 123px 339px, 126px 339px;
  .imgBox {
    width: 1176px;
    margin: 0 auto;
    img {
      width: 1176px;
    }
  }
  @media screen and (max-width: 750px) {
    padding: 0.7rem 0.35rem 0.67rem;
    background: url(${bgLeft}) no-repeat left 2rem ,url(${bgRight}) no-repeat right 0.5rem;
    background-size: 0.8rem, 0.8rem;
    .imgBox {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`

const MediaCoverage = styled.div`
  padding: 60px 0 120px;
  .content {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    align-items: center;
    width: 1176px;
    margin: 80px auto 0;
    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: border-box;
      width: 350px;
      height: 408px;
      padding: 20px 16px;
      background: #1F1F1F;
      margin-top: 30px;
      margin-right: 62px;
      border: 2px solid transparent;
      /* border-radius: 7px; */
      text-decoration: none;
      box-shadow: 0 10px 10px #090909;
      &::before {
        position: absolute;
        top: 0;
        left: 153px;
        content: '';
        width: 33px;
        height: 5px;
        border-top: 5px solid #F9700C;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
      }
      p {
        margin: 12px 0 0;
        font-size: 20px;
        line-height: 1.5;
        color: #fff;
      }
      .big {
        height: 180px;
      }
      .small {
        height: 28px;
      }
      &:hover {
        border-image: linear-gradient(180deg, rgba(40, 40, 40, 1), rgba(249, 106, 10, 1)) 2 2;
      }
    }
    .item:nth-child(-n + 3) {
      margin-top: 0;
    }
    .item:nth-child(3n) {
      margin-right: -63px;
    }
  }

  @media screen and (max-width: 750px) {
    padding: 0.6rem 0.35rem 1.4rem;
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0.6rem auto 0;
      .item {
        width: 100%;
        height: 6.2rem;
        padding: 0.3rem 0.2rem;
        margin-right: 0;
        &::before {
          left: 45%;
          width: 0.3rem;
          height: 0.1rem;
          border: 0;
        }
        p {
          margin: 0.1rem 0 0;
          font-size: 0.23rem;
        }
        .big {
          height: auto;
          width: 100%;
        }
        .small {
          height: 0.4rem;
        }
      }
      .item + .item {
        margin-top: 0.4rem;
      }
      .item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  
`

const Footer = styled.footer`
  padding: 60px 0 98px;
  background-color: #131313;
  .top {
    width: 1176px;
    margin: 0 auto 30px;
    img {
      width: 255px;
    }
  }
  .box {
    display: flex;
    justify-content: space-between;
    width: 1176px;
    margin: 0 auto;
    .left {
      flex: 1;
      margin-right: 100px;
      p {
        margin: 0 0 36px;
        color: #fff;
        font-size: 16px;
        line-height: 32px;
      }
      .list {
        display: flex;
        .item {
          font-size: 0;
          a {
            display: block;
          }
          a.coming {
            &:hover {
              position: relative;
              &::before {
                content: '';
                position: absolute;
                left: 22px;
                bottom: -10px;
                border-top: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 4px solid #fff;
                border-left: 4px solid transparent;
              }
              &::after {
                content: 'Coming soon';
                position: absolute;
                left: 0;
                bottom: -36px;
                width: 100px;
                padding: 5px 10px;
                font-size: 14px;
                color: #333;
                background-color: rgba(255,255,255,1);
              }
            }
          }
          img {
            width: 56px;
            height: 56px;
          }
        }
        .item + .item {
          margin-left: 20px;
        }
      }
    }
    .right {
      width: 648px;
      dl {
        margin: 0;
        dt {
          margin-bottom: 8px;
          span {
            padding-bottom: 6px;
            font-size: 16px;
            line-height: 32px;
            color: rgba(255,255,255,0.5);
            border-bottom: 2px solid #F9770E;
          }
        }
        dd {
          margin-left: 0;
          font-size: 16px;
          line-height: 32px;
          color: rgba(255,255,255,0.5);
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    padding: 0.6rem 0.35rem 1.4rem;
    .top {
      font-size: 0;
      width: 100%;
      margin: 0 auto 0.3rem;
      img {
        width: 3.1rem;
      }
    }
    .box {
      display: block;
      width: 100%;
      margin: 0 auto;
      .left {
        margin-right: 0;
        margin-bottom: 0.5rem;
        p {
          margin: 0 0 0.36rem;
          color: #fff;
          font-size: 0.22rem;
          line-height: 0.32rem;
        }
        .list {
          display: flex;
          .item {
            font-size: 0;
            a.coming {
              &:hover {
                position: relative;
                &::before {
                  content: '';
                  position: absolute;
                  left: 18px;
                  bottom: -8px;
                  border-top: 4px solid transparent;
                  border-right: 4px solid transparent;
                  border-bottom: 4px solid #fff;
                  border-left: 4px solid transparent;
                }
                &::after {
                  content: 'Coming soon';
                  position: absolute;
                  left: 0;
                  bottom: -30px;
                  width: 1.5rem;
                  padding: 5px 10px;
                  font-size: 0.22rem;
                  color: #333;
                  background-color: rgba(255,255,255,1);
                }
              }
            }
            img {
              width: 0.7rem;
              height: 0.7rem;
            }
          }
          .item + .item {
            margin-left: 0.24rem;
          }
        }
      }
      .right {
        width: 100%;
        dl {
          margin: 0;
          dt {
            margin-bottom: 8px;
            font-size: 0;
            span {
              padding-bottom: 0.08rem;
              font-size: 0.22rem;
              line-height: 0.32rem;
              font-weight: bold;
              color: rgba(255,255,255,0.5);
              border-bottom: 2px solid #F9770E;
            }
          }
          dd {
            margin-left: 0;
            font-size: 0.22rem;
            line-height: 0.32rem;
            color: rgba(255,255,255,0.5);
          }
        }
      }
    }
  }
`

export default Home;