import React, { PureComponent } from 'react'
import styled from 'styled-components'


class Onepager extends PureComponent {
  render(){
    return (
      <Layout>
        <img src="https://cdn.lcyoufu.com/assets/MOMA/MOMA_One_Pager.jpg"/>
      </Layout>
    )
  }
}

const Layout = styled.div`
  width: 100%;
  text-align: center;
  img {
    width: 1200px;
  }
  @media screen and (max-width: 750px){
    img {
      width: 100%;
    }
  }
`

export default Onepager;