import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './Home.js';
import Onepager from './Onepager.js';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route path='/' exact component={Home}></Route>
        <Route path='/Onepager' exact component={Onepager}></Route>
      </BrowserRouter>
    );
  }
}

export default App;

